import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const BUYER_GET_HELP_ZENDESK = 'buyer-get-help-modal-zendesk' as const;

export const isGetHelpModalZendeskVariant = (): boolean =>
    getAbTestV2(BUYER_GET_HELP_ZENDESK)?.variant === 'variant';

export const trackGetHelpZendeskVariant = (): void => {
    trackAbTestV2Variant(BUYER_GET_HELP_ZENDESK);
};
