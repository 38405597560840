import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

export const SELLER_BRANDING_REMOVAL = 'SellerBrandRemoval' as const;
export const SELLER_BRANDING_REMOVAL_MESSAGE_CENTER = 'SellerBrandRemovalMessageCenter' as const;

export const isSellerBrandingRemovalTestVariant = (): boolean => {
    return getAbTestV2(SELLER_BRANDING_REMOVAL)?.variant === 'variant';
};

export const isSellerBrandingRemovalMessageCenterVariant = (): boolean => {
    return getAbTestV2(SELLER_BRANDING_REMOVAL_MESSAGE_CENTER)?.variant === 'variant';
};

export const trackSellerBrandingRemovalAbTestVariant = (): void => {
    trackAbTestV2Variant(SELLER_BRANDING_REMOVAL);
};

export const trackSellerBrandingRemovalMessageCenterAbTestVariant = (): void => {
    trackAbTestV2Variant(SELLER_BRANDING_REMOVAL_MESSAGE_CENTER);
};
