/**
 * @generated SignedSource<<521f8ae41e2afb42e0345c58eb693e2e>>
 * @relayHash 7b651aa36bb8b094d1642ac0b4222f2b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/488.0.0-2024-11-12T17:14:07.765Z/SendMessageFormMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateContactRequestMutationInput = {
  attachment?: any | null;
  buyerId?: string | null;
  clientMutationId?: string | null;
  conversationId?: string | null;
  email?: string | null;
  itemPk?: string | null;
  message?: string | null;
  name?: string | null;
  orderId?: string | null;
  sellerId?: string | null;
  subtopic?: string | null;
  topic?: string | null;
  url?: string | null;
};
export type SendMessageFormMutation$variables = {
  input: CreateContactRequestMutationInput;
};
export type SendMessageFormMutation$data = {
  readonly CreateContactRequestMutation: {
    readonly recordId: string | null;
  } | null;
};
export type SendMessageFormMutation = {
  response: SendMessageFormMutation$data;
  variables: SendMessageFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateContactRequestMutationPayload",
    "kind": "LinkedField",
    "name": "CreateContactRequestMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendMessageFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendMessageFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/488.0.0-2024-11-12T17:14:07.765Z/SendMessageFormMutation",
    "metadata": {},
    "name": "SendMessageFormMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "14ec28b3585c34d2fe338cece5ff1aa3";

export default node;
