import { FC, Suspense, lazy } from 'react';

import { GetHelpModalProps } from '../src/types';

const GetHelpModal = lazy(() => import(/* webpackChunkName: "GetHelpModal" */ '../src/GetHelp'));

const GetHelpModalLazy: FC<GetHelpModalProps> = props =>
    props.isOpen ? (
        <Suspense fallback={null}>
            <GetHelpModal {...props} isOpen />
        </Suspense>
    ) : null;

export default GetHelpModalLazy;
