/**
 * @generated SignedSource<<e75d0173589c2f98b0b6a7d13be3d7fa>>
 * @relayHash 51a2952d1c677c2ba58161081e80c6c9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/488.0.0-2024-11-12T17:14:07.765Z/ContactBuyerFormMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveMessageInput = {
  attachmentDir?: string | null;
  attempt?: number | null;
  clientMutationId?: string | null;
  conversationServiceId?: string | null;
  isFromMessageCenter?: boolean | null;
  isFromSeller: boolean;
  itemAttachmentIds?: ReadonlyArray<string | null> | null;
  itemId?: string | null;
  opopABTestBucket?: string | null;
  pageContext?: string | null;
  recipientId?: string | null;
  senderId: string;
  subject?: string | null;
  textBody: string;
  videoAttachments?: ReadonlyArray<MessageVideoAttachmentInput | null> | null;
};
export type MessageVideoAttachmentInput = {
  fileSize?: number | null;
  vimeoId: string;
};
export type ContactBuyerFormMutation$variables = {
  input: SaveMessageInput;
};
export type ContactBuyerFormMutation$data = {
  readonly saveMessage: {
    readonly conversation: {
      readonly serviceId: string | null;
    } | null;
  } | null;
};
export type ContactBuyerFormMutation = {
  response: ContactBuyerFormMutation$data;
  variables: ContactBuyerFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactBuyerFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveMessagePayload",
        "kind": "LinkedField",
        "name": "saveMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactBuyerFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveMessagePayload",
        "kind": "LinkedField",
        "name": "saveMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/488.0.0-2024-11-12T17:14:07.765Z/ContactBuyerFormMutation",
    "metadata": {},
    "name": "ContactBuyerFormMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4995cafeaef530ece3af271604da2521";

export default node;
