/**
 * @generated SignedSource<<a10a73f639777e8613aa8dfd13f7e50e>>
 * @relayHash afc65620fa17cb4583039e3b0a1c9725
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/488.0.0-2024-11-12T17:14:07.765Z/OrdersListQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrdersListQuery$variables = {
  excludeCanceled: boolean;
  hasSellerId: boolean;
  hasUserId: boolean;
  searchTerm: string;
  sellerId: string;
  userId: string;
  viewName?: string | null;
};
export type OrdersListQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrdersList_viewer">;
  };
};
export type OrdersListQuery = {
  response: OrdersListQuery$data;
  variables: OrdersListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCanceled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSellerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewName"
},
v7 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "OrdersList_viewer"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": ""
  },
  {
    "kind": "Variable",
    "name": "excludeCanceled",
    "variableName": "excludeCanceled"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  },
  {
    "kind": "Variable",
    "name": "sellerId",
    "variableName": "sellerId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  },
  {
    "kind": "Variable",
    "name": "viewName",
    "variableName": "viewName"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v15 = [
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": (v8/*: any*/),
    "concreteType": "TransactionsPaginatedSearchConnection",
    "kind": "LinkedField",
    "name": "transactionsPaginatedSearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionsPaginatedSearchEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkoutDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Item",
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "size",
                        "value": "small"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "firstPhotoWebPath",
                    "storageKey": "firstPhotoWebPath(size:\"small\")"
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCanceled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionCurrentBuyerTimelineStatus",
                "kind": "LinkedField",
                "name": "currentBuyerTimelineStatus",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionCurrentSellerTimelineStatus",
                "kind": "LinkedField",
                "name": "currentSellerTimelineStatus",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionHistory",
                "kind": "LinkedField",
                "name": "transactionHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoricalEvent",
                    "kind": "LinkedField",
                    "name": "historicalEvents",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "buyerCopy",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "isBomtOptimizationVariant",
                            "value": true
                          }
                        ],
                        "concreteType": "buyerTransactionStatus",
                        "kind": "LinkedField",
                        "name": "buyerStatusCopy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "header",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "buyerStatusCopy(isBomtOptimizationVariant:true)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Return",
                "kind": "LinkedField",
                "name": "returns",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showReturnDownloadShippingLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShipmentQuote",
                    "kind": "LinkedField",
                    "name": "shipmentQuote",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBuyerAccepted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDealerAccepted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FinancialAmount",
                        "kind": "LinkedField",
                        "name": "subTotalAmount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConvertedAmountList",
                            "kind": "LinkedField",
                            "name": "convertedAmountList",
                            "plural": true,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currency",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Shipment",
                    "kind": "LinkedField",
                    "name": "shipment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasUnknownShipmentTracking",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPreTransit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentTracking",
                        "kind": "LinkedField",
                        "name": "shipmentTracking",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "labelUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Carrier",
                            "kind": "LinkedField",
                            "name": "carrier",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCheckoutProcessing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "didCheckoutProcessingFail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionSummaryObject",
                "kind": "LinkedField",
                "name": "summary",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FinancialAmount",
                    "kind": "LinkedField",
                    "name": "balanceDueAmount",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentAuthorization",
                    "kind": "LinkedField",
                    "name": "checkoutPaymentAuthorization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "paymentMethod",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paymentMethodType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceSummary",
                    "kind": "LinkedField",
                    "name": "invoiceSummary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FinancialAmount",
                        "kind": "LinkedField",
                        "name": "amountToSettleBuyerCurrentInvoice",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "acceptQuote",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "actionCode",
                    "value": "BUYER_APPROVE_RETURN_QUOTE"
                  }
                ],
                "kind": "ScalarField",
                "name": "hasAction",
                "storageKey": "hasAction(actionCode:\"BUYER_APPROVE_RETURN_QUOTE\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buyerCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentAuthorization",
                "kind": "LinkedField",
                "name": "paymentAuthorizations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentProviderTransaction",
                    "kind": "LinkedField",
                    "name": "providerTransaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentProviderTransactionResponse",
                        "kind": "LinkedField",
                        "name": "responses",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v8/*: any*/),
    "filters": [
      "userId",
      "sellerId",
      "searchTerm",
      "viewName",
      "excludeCanceled"
    ],
    "handle": "connection",
    "key": "OrdersList_transactionsPaginatedSearch",
    "kind": "LinkedHandle",
    "name": "transactionsPaginatedSearch"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v7/*: any*/)
          },
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v7/*: any*/)
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v17/*: any*/)
          },
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v17/*: any*/)
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/488.0.0-2024-11-12T17:14:07.765Z/OrdersListQuery",
    "metadata": {},
    "name": "OrdersListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "71831222996146b76e665aa290b2f3a9";

export default node;
