import { FC, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Button } from 'dibs-elements/exports/Button';
import { GetHelpZendeskContactSupport } from './GetHelpZendeskContactSupport';
import { GetHelpZendeskEmbeddedTopBlock } from './GetHelpZendeskEmbeddedTopBlock';

import getBuyerSupportLink from 'dibs-support-link/exports/getBuyerSupportLink';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { initChat } from '../liveChat/initSalesforceChat';
import { CHAT_TYPE_MAP } from '../liveChat/constants';
import { adjustPdpSellerChatPosition } from '../liveChat/helpers/adjustPdpSellerChatPosition';
import { trackGetHelpZendeskEmbeddedEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskEmbeddedQuery } from './__generated__/GetHelpZendeskEmbeddedQuery.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskEmbedded.scss';

const GetHelpZendeskEmbedded: FC = () => {
    const environment = useRelayEnvironment();
    const {
        state: { userId, sellerId, isSeller, onChatEnd },
    } = useContext(GetHelpZendeskContext);

    const { viewer } = useLazyLoadQuery<GetHelpZendeskEmbeddedQuery>(
        graphql`
            query GetHelpZendeskEmbeddedQuery(
                $articleId: String
                $hasSellerId: Boolean!
                $hasUserId: Boolean!
                $isSeller: Boolean
                $sellerId: String!
                $userId: String!
            ) {
                viewer {
                    user(userId: $userId) @include(if: $hasUserId) {
                        profile {
                            firstName
                        }
                    }
                    contact1stdibsConfig(isSeller: $isSeller) {
                        isAgentAvailable
                        showChannelChat
                    }
                    ...GetHelpZendeskContactSupport_viewer
                }
            }
        `,
        {
            articleId: '',
            hasSellerId: !!sellerId,
            hasUserId: !!userId,
            isSeller,
            sellerId,
            userId,
        }
    );

    const { user } = viewer;
    const { firstName } = user?.profile || {};
    const { contact1stdibsConfig } = viewer;
    const { isAgentAvailable, showChannelChat } = contact1stdibsConfig || {};

    useEffect(() => {
        const chatShouldBeInitialized = showChannelChat && (isAgentAvailable || isSeller);

        // initiating chat in initial view to reduce wait time for chat button to be enabled in topic view
        if (chatShouldBeInitialized) {
            initChat({
                chatType: isSeller ? CHAT_TYPE_MAP.seller : CHAT_TYPE_MAP.buyer,
                environment,
                initiated: true,
                afterDestroy: () => {
                    if (typeof onChatEnd === 'function') {
                        onChatEnd();
                    }
                    if (!isSeller) {
                        adjustPdpSellerChatPosition({ reset: true });
                    }
                },
            });
        }
    }, [environment, isAgentAvailable, showChannelChat, isSeller, onChatEnd]);

    return (
        <div className={classnames(styles.container, dibsCss.ptLarge)}>
            <h1
                className={classnames(
                    styles.title,
                    dibsCss.sassyFontHeaderLarge,
                    dibsCss.textCenter,
                    dibsCss.mt0,
                    dibsCss.mbLarge
                )}
            >
                {firstName ? (
                    <FormattedMessage
                        id="getHelp.GetHelpZendeskEmbedded.introHeaderLoggedIn"
                        defaultMessage="Hi {firstName}, how can we help?"
                        values={{
                            firstName: (
                                <span className={dibsCss.sassyFontHeaderXLargeItalic}>
                                    {firstName}
                                </span>
                            ),
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="getHelp.GetHelpZendeskEmbedded.introHeader"
                        defaultMessage="What can we help you with?"
                    />
                )}
            </h1>
            <div className={classnames(styles.blocksContainer, dibsCss.grid, dibsCss.gapSmall)}>
                <GetHelpZendeskEmbeddedTopBlock
                    title={
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskEmbedded.block1Title"
                            defaultMessage="Buying on 1stdibs"
                        />
                    }
                    articles={[
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article1"
                                    defaultMessage="What Is 1stDibs?"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14487684386843-What-Is-1stDibs'
                            ),
                            trackingLabel: 'What Is 1stDibs?',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article2"
                                    defaultMessage="Seller Review Process"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14488190682267-Seller-Review-Process'
                            ),
                            trackingLabel: 'Seller Review Process',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article3"
                                    defaultMessage="Authenticity Guarantee Policy"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/20416652048027-Authenticity-Guarantee-Policy'
                            ),
                            trackingLabel: 'Authenticity Guarantee Policy',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article4"
                                    defaultMessage="Negotiating Prices"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14506784591259-Negotiating-Prices'
                            ),
                            trackingLabel: 'Negotiating Prices',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article5"
                                    defaultMessage="Private Offers"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14507995129755-Private-Offers'
                            ),
                            trackingLabel: 'Private Offers',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block1Article6"
                                    defaultMessage="Customs Duties and Taxes for International Shipments"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14491601612827-Customs-Duties-and-Taxes-for-International-Shipments'
                            ),
                            trackingLabel: 'Customs Duties and Taxes for International Shipments',
                        },
                    ]}
                    viewAllLink={getBuyerSupportLink(
                        '/en-us/categories/14331958044187-Buying-on-1stDibs'
                    )}
                    imageUrl="https://a.1stdibscdn.com/dist/adhoc/get-help/buying-on-1stdibs.jpg"
                    onViewAllClick={() =>
                        trackGetHelpZendeskEmbeddedEvent({
                            stepInteractionName: 'contact us page - support center section opened',
                            trigger: 'buying',
                        })
                    }
                />
                <GetHelpZendeskEmbeddedTopBlock
                    title={
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskEmbedded.block2Title"
                            defaultMessage="Selling on 1stdibs"
                        />
                    }
                    articles={[
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article1"
                                    defaultMessage="Selling on 1stDibs"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14490370639515-Selling-on-1stDibs'
                            ),
                            trackingLabel: 'Selling on 1stDibs',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article2"
                                    defaultMessage="What Percentage Does 1stDibs Take from a Sale?"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/17416185631387-What-Percentage-Does-1stDibs-Take-from-a-Sale'
                            ),
                            trackingLabel: 'What Percentage Does 1stDibs Take from a Sale?',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article3"
                                    defaultMessage="What Is the Monthly Fee for 1stDibs?"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/17416136155291-What-Is-the-Monthly-Fee-for-1stDibs'
                            ),
                            trackingLabel: 'What Is the Monthly Fee for 1stDibs?',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article4"
                                    defaultMessage="What Is a Vetted Seller?"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/17416099156635-What-Is-a-Vetted-Seller'
                            ),
                            trackingLabel: 'What Is a Vetted Seller?',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article5"
                                    defaultMessage="Item Appraisal"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14490408074267-Item-Appraisal'
                            ),
                            trackingLabel: 'Item Appraisal',
                        },
                        {
                            label: (
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskEmbedded.block2Article6"
                                    defaultMessage="Applying to Sell on 1stDibs"
                                />
                            ),
                            url: getBuyerSupportLink(
                                '/en-us/articles/14490330236955-Applying-to-Sell-on-1stDibs'
                            ),
                            trackingLabel: 'Applying to Sell on 1stDibs',
                        },
                    ]}
                    viewAllLink={getBuyerSupportLink(
                        '/en-us/categories/14480919967643-Selling-on-1stDibs'
                    )}
                    imageUrl="https://a.1stdibscdn.com/dist/adhoc/get-help/selling-on-1stdibs.jpg"
                    onViewAllClick={() =>
                        trackGetHelpZendeskEmbeddedEvent({
                            stepInteractionName: 'contact us page - support center section opened',
                            trigger: 'selling',
                        })
                    }
                />
            </div>
            <div
                className={classnames(
                    styles.viewSupportCenterButtonWrapper,
                    dibsCss.flex,
                    dibsCss.justifyCenter,
                    dibsCss.myMedium
                )}
            >
                <Button
                    href={getBuyerSupportLink('/')}
                    target="_blank"
                    className={styles.viewSupportCenterButton}
                    onClick={() =>
                        trackGetHelpZendeskEmbeddedEvent({
                            stepInteractionName: 'contact us page - support center opened',
                        })
                    }
                >
                    <FormattedMessage
                        id="getHelp.GetHelpZendeskEmbedded.viewSupportCenterLink"
                        defaultMessage="View support center"
                    />
                </Button>
            </div>
            <div
                className={classnames(
                    dibsCss.wScreen,
                    dibsCss.h1px,
                    dibsCss.bgDolphin,
                    dibsCss.absolute,
                    dibsCss.left0
                )}
            />
            <div
                className={classnames(
                    styles.contactSupportWrapper,
                    dibsCss.flex,
                    dibsCss.justifyCenter,
                    dibsCss.textCenter,
                    dibsCss.ptMedium
                )}
            >
                <div className={dibsCss.w500Px}>
                    <h2
                        className={classnames(
                            dibsCss.sassyFontHeaderLarge,
                            dibsCss.mt0,
                            dibsCss.mbSmall
                        )}
                    >
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskEmbedded.contactSupportHeader"
                            defaultMessage="Contact Us"
                        />
                    </h2>
                    <div className={classnames(dibsCss.sassyFontBodyMedium, dibsCss.mySmall)}>
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskEmbedded.contactSupportInfo"
                            defaultMessage="Select how you want to contact us"
                        />
                    </div>
                    <GetHelpZendeskContactSupport viewer={viewer} showHeader={false} />
                </div>
            </div>
        </div>
    );
};

export { GetHelpZendeskEmbedded };
